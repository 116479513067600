import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby-theme-material-ui";

const linkData = [
  {
    title: "Account Settings",
    items: [
      {
        title: "Personal Information",
        link: "/account",
      },
      {
        title: "Connected Accounts",
        link: "/account/connected",
      },
    ],
  },
];

const NavigationBarContainer = styled.div`
  width: 25rem;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  align-self: start;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

  img {
    margin-top: 1rem;
    height: 5.5rem;
  }

  > a {
    font-size: 1.15rem;
  }

  .log-out-link {
    width: 100%;
    padding: 1rem;
  }

  .navigation-link {
    width: 100%;
  }
`;

const GroupTitle = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 1.5rem;
`;

const NavigationLink = styled(Link)`
  border-bottom: 1px solid #999999;
  padding: 0.75rem 1rem;
  color: #444444;
  font-size: 1rem;

  &.selected-link {
    border-bottom: 1px solid #579740;
    color: #579740;
    background-color: #cfebc5;
  }
`;

export const BankAccountNavigation = () => {
  return (
    <NavigationBarContainer>
      {linkData.map((group) => (
        <Fragment>
          <GroupTitle>{group.title}</GroupTitle>
          {group.items.map((item) => (
            <NavigationLink
              to={item.link}
              className="navigation-link"
              activeClassName="selected-link"
            >
              {item.title}
            </NavigationLink>
          ))}
        </Fragment>
      ))}
      <div style={{ flex: 1 }}></div>
    </NavigationBarContainer>
  );
};
